.wrapper {
  margin-top: 2em;
}

.dropdown-wrapper {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.entry-state-dropdown {
  margin-right: 0.5em;
  max-width: 220px;
}

.namespace-dropdown {
  margin-right: 0.5em;
  max-width: 300px;
}

.success-message-wrapper {
  display: flex;
  flex-direction: column;
}

.success-message-note {
  width: 90%;
}

.success-message-paragraph {
  margin-top: 1em;
  width: 90%;
}

.failure-message-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.failure-message-note {
  width: 90%;
}

.failure-error-info {
  max-width: 50%;
  overflow: hidden;
  overflow-wrap: break-word;
}

.textarea-wrapper {
  margin-top: 2rem;
  max-width: 50%;
}

.textarea {
  margin-top: 1rem;
}

.red {
  color: red;
}

.bold {
  font-weight: bold;
}