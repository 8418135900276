.retry-button {
  width: 150px;
  margin-top: 2em;
}

.author-button {
  width: 240px;
  margin-top: 2em;
}

.auto-publish-checkbox {
  margin-top: 1em;
}