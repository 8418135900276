.translation-list-wrapper {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tl-item-checkbox {
  margin-right: 0.5em;
}

.tl-item-key {
}

.tl-item-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tl-item-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.tl-accordion-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-all {
  margin-left: 1em;
  margin-bottom: 1em;
}

.translation-stats {
  margin-top: 1em;
  margin-left: 1em;
  margin-bottom: 1.5em;
}